import React, { useEffect, useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Sketch from '../../assets/images/home/service/sketch.png'
import Xd from '../../assets/images/home/service/xd.png'
import PS from '../../assets/images/home/service/ps.png'
import Capa from '../../assets/images/home/service/capa.png'
import Figma from '../../assets/images/home/service/figma.png'
import Ai from '../../assets/images/home/service/ai.png'
import Flutter from '../../assets/images/home/service/flutter.png'
import Shopify from '../../assets/images/home/service/shop.png'
import ReactImg from '../../assets/images/home/service/react-icon.png'
import W from '../../assets/images/home/service/w.png'
import Node from '../../assets/images/home/service/node.png'
import Lara from '../../assets/images/home/service/laravel-2.png'
import Lr from '../../assets/images/home/service/l-r.png'


export default function Technologies() {
    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 150);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])


    
    return (
        <>
            <section className='technologies_section'>

                <div className={isScrollValueMoreThanHeaderHeight ? "para_main_text para_sticky-top" : "para_main_text "}>
                    <h2><strong>  Technologies </strong> <br /> we using </h2>
                </div>
                <div className='para_logo_main'>
                    <Parallax speed={-25}>
                        <div className='para_item1 item'>
                            <img src={Sketch} />
                        </div>
                    </Parallax >

                    <Parallax speed={20}>
                        <div className='para_item2 item'>
                            <img src={Xd} />
                        </div>
                    </Parallax >

                    <Parallax speed={35}>
                        <div className='para_item3 item'>
                            <img src={PS} />
                        </div>
                    </Parallax >

                    <Parallax speed={30}>
                        <div className='para_item4 item'>
                            <img src={Capa} />
                        </div>
                    </Parallax >

                    <Parallax speed={-20}>
                        <div className='para_item5 item'>
                            <img src={Figma} />
                        </div>
                    </Parallax >


                    <Parallax speed={20}>
                        <div className='para_item6 item'>
                            <img src={Ai} />
                        </div>
                    </Parallax>

                    <Parallax speed={45}>
                        <div className='para_item7 item'>
                            <img src={Lr} />
                        </div>
                    </Parallax >

                    <Parallax speed={-15}>
                        <div className='para_item8 item'>
                            <img src={Flutter} />
                        </div>
                    </Parallax >

                    <Parallax speed={5}>
                        <div className='para_item9 item'>
                            <img src={Shopify} />
                        </div>
                    </Parallax >

                    <Parallax speed={45}>
                        <div className='para_item10 item'>
                            <img src={ReactImg} />
                        </div>
                    </Parallax >

                    <Parallax speed={5}>
                        <div className='para_item11 item'>
                            <img src={Node} />
                        </div>
                    </Parallax >

                    <Parallax speed={5}>
                        <div className='para_item12 item'>
                            <img src={Lara} />
                        </div>
                    </Parallax >

                    <Parallax speed={5}>
                        <div className='para_item13 item'>
                            <img src={W} />
                        </div>
                    </Parallax >

                </div>
            </section>
        </>
    )
}
