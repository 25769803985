import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Button, Container, Grid } from '@mui/material'




export default function Revemp() {

    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);


    useEffect(() => {

        const handleScroll = () => {
            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 100);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])



    return (
        <>
            <section className='revemp_section'>
                <Container maxWidth="xl" spacing={1} className='custome_container'>
                    <Grid container spacing={4}>
                        <Grid item lg={4} xs={12}>
                            <div className={isScrollValueMoreThanHeaderHeight ? "service_title work_sticky-top" : "service_title"}>
                                <h2 className='infi_similar_title'> Revamp <br />
                                    Design </h2>
                                <p className='infi_similar_desc'> Whether it's a single page or a custom website, relaunch an old design into something new for your current and future customers with us. </p>
                                <Button variant='contained' className='revemp_btn'>   Book A Call  </Button>
                            </div>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <div className='revemp_right_box'>

                                <ul className='rev_ul1'>
                                    <li>
                                        <h2>Keep your Design Up-to Date:</h2>
                                        <p> Refresh your current design to
                                            meet today's demand. </p>
                                    </li>
                                    <li>
                                        <h2>Launch new pages consistently:</h2>
                                        <p> All new assets will adhere to your
                                            branding for consistency. </p>
                                    </li>
                                </ul>

                                <ul className='rev_ul2'>
                                    <li style={{listStyle: 'disc'}}>
                                        <h2>What’s Included in our
                                            Revamp/Redesign Service:
                                        </h2>
                                    </li>
                                    <li>
                                        <div className='rev_text_desc'>
                                            <p>Website </p>
                                            <p> Mobile App </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='rev_text_desc'>
                                            <p>Landing Page</p>
                                            <p> Brand Identity </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Grid>

                    </Grid>
                </Container>
            </section>
        </>
    )
}
