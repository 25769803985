import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Item1 from '../../assets/images/home/experience.svg'
import Item2 from '../../assets/images/home/advanced.svg'
import Item3 from '../../assets/images/home/client.svg'
import Item4 from '../../assets/images/home/project.svg'
import { Button, Container, Grid } from '@mui/material'
import CountUp from 'react-countup';


export default function Project() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 2000,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section className='project_section'>
                <Container maxWidth="xl" spacing={1} className='custome_container' sx={{ p: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item lg={12} xs={12}>
                            <div className='logo_slide_main'>

                                <Grid container spacing={1} justifyContent="center">

                                    <Grid item lg={3} md={6} sm={6} xs={6}>
                                        <div className='logo_item' data-aos="fade-up" data-aos-delay="100">
                                            <CountUp start={0} end={12} duration={2.5} enableScrollSpy >
                                                {/* <h2> 0 </h2> */}
                                            </CountUp>
                                            <p>
                                                Years <br />
                                                Experience
                                            </p>
                                        </div>
                                    </Grid>

                                    <Grid item lg={3} md={6} sm={6} xs={6}>
                                        <div className='logo_item' data-aos="fade-up" data-aos-delay="300">
                                            {/* <CountUp start={0} end={12} duration={2.5} enableScrollSpy suffix="+"> */}
                                            <CountUp start={0} end={12} duration={2.5} enableScrollSpy>
                                                {/* <h2> 0 + </h2> */}
                                            </CountUp>
                                            <p>
                                                advanced <br />
                                                Tools
                                            </p>
                                        </div>
                                    </Grid>

                                    <Grid item lg={3} md={6} sm={6} xs={6}>
                                        <div className='logo_item' data-aos="fade-up" data-aos-delay="500">
                                            <CountUp start={0} end={52} duration={2.5} enableScrollSpy>
                                                {/* <h2> 0 </h2> */}
                                            </CountUp>
                                            <p>
                                                Clients <br />
                                                Satisfied
                                            </p>
                                        </div>
                                    </Grid>

                                    <Grid item lg={3} md={6} sm={6} xs={6}>
                                        <div className='logo_item' data-aos="fade-up" data-aos-delay="700">
                                            <CountUp start={0} end={99} duration={2.5} enableScrollSpy >
                                                <h2> + </h2>
                                            </CountUp>
                                            <p>
                                                Projects <br />
                                                Completed
                                            </p>
                                        </div>
                                    </Grid>

                                </Grid>

                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </>
    )
}
