import { Button, Container, FormControl, Grid, Input, TextField } from '@mui/material';
import React from 'react'
import BackBtn from "../../assets/images/home/Back-Arrow.svg"
import Logo from '../../assets/images/logo.png'
import "../../assets/css/contact.css"
import { Link } from 'react-router-dom';
import { Label } from '@mui/icons-material';
import { LiaArrowLeftSolid } from "react-icons/lia";
import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

export function Contact() {
    const [phone, setPhone] = useState('+91');


    return (
        <>
            <section className='contact_section'>
                <Container maxWidth="xl" className='custome_container'>
                    <Grid container spacing={2}>
                        <Grid item lg={6}>
                            <div className='contact_title'>
                                <div className='top_item_main'>
                                    <div className='back_btn circle'>
                                        <Link to="/">   <LiaArrowLeftSolid /> </Link>
                                    </div>
                                    <div className='contact_logo back_btn mob'>
                                        <img src={Logo} />
                                    </div>
                                </div>
                                <h2>
                                    Let’s Discuss
                                    Your Upcoming
                                    Project
                                </h2>
                                <p>
                                    Share your creative ideas with us and  <br />
                                    get beautiful designs that impress
                                    <br />and engage.
                                </p>
                            </div>
                        </Grid>
                        <Grid item lg={6}>

                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <div className='contact_logo back_btn desk'>
                                        <img src={Logo} />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={{ lg: 6, sm: 3 }}>


                                <Grid item xs={12}>
                                    <div className='contact_inp'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <label> Full Name </label>
                                            <Input placeholder="Full Name" />
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12} sx={{ pr: { lg: 4, sm: 0, xs: 0 } }}>
                                    <div className='contact_inp'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <label> Email Address </label>
                                            <Input placeholder="Email Address" />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item lg={6} sm={12} xs={12}>
                                    <div className='contact_inp cuntry_phone_number'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <label> Contact Number </label>
                                            {/* <Input placeholder="Contact Number" /> */}
                                            <PhoneInput
                                                defaultCountry="ia"
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>


                                <Grid item xs={12}>
                                    <div className='contact_inp'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <label> Company Name </label>
                                            <Input placeholder="Company Name" />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='contact_inp'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <label> Tell us about the project </label>
                                            {/* <TextField label="Standard" variant="standard" /> */}
                                            <TextField
                                                id="standard-multiline-static"
                                                multiline
                                                rows={4}
                                                placeholder="Project purpose, target audience, competitors,
                                                color preferences, inspiration sites, etc."
                                                variant="standard"
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button className='contact_sub_btn' variant="outlined">
                                        Submit
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </section >
        </>
    )
}


export default Contact;
