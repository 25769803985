import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Box, Button, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import Logo from '../../assets/images/Infi-Logo.svg'
import '../../assets/css/header.css'
import { CiMenuFries } from "react-icons/ci";
import SendIcon from '@mui/icons-material/Send';
import DraftsIcon from '@mui/icons-material/Drafts';

export default function Header() {
    const onPress = (e) => {
        e.preventDefault();
        const target = window.document.getElementById(
            e.currentTarget.href.split("#")[1]
        );
        if (target) {
            target.scrollIntoView({ behavior: "smooth" });
        }
    };
    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
   
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (inOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(inOpen);
    };

    useEffect(() => {

        const handleScroll = () => {

            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 150);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])



    return (
        <>
            <header className={isScrollValueMoreThanHeaderHeight ? "header_top fixed_header" : "header_top"}>

                <div className='main_header'>

                    {/* <div className='header_logo' style={{visibility: 'hidden'}}> */}
                    <div className='header_logo'>
                        <img src={Logo} alt="main-logo" />
                    </div>

                    <div className='menu_item'>


                        <ul>
                            <li>
                                <Link to="#service" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="service">
                                        Services
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="#RecentWork" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="RecentWork">
                                        Portfolio
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="#HowWeWork" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="HowWeWork">
                                        How We Work
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="#Revamp" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="Revamp">
                                        Revamp
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="#WhyUs" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="WhyUs">
                                        Why Us
                                    </div>
                                </Link>
                            </li>

                        </ul>
                    </div>

                    <div className='header_btn_main'>
                        <Link to="/contact-us"> <Button className='header_btn' variant='outlined'>  CONTACT </Button>  </Link>
                        <div className='menu_toggle_btn'>
                            <CiMenuFries onClick={toggleDrawer(true)} />
                        </div>
                    </div>

                </div>

            </header>



            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Box
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <div className='mobile_drawer_main'>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"

                        >
                            <ListItemButton>
                                <Link to="#service" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="service">
                                        Services
                                    </div>
                                </Link>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <li>
                                    <Link to="#RecentWork" onClick={(e) => onPress(e)} >
                                        <div data-to-scrollspy-id="RecentWork">
                                            Portfolio
                                        </div>
                                    </Link>
                                </li>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Link to="#HowWeWork" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="HowWeWork">
                                        How We Work
                                    </div>
                                </Link>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Link to="#Revamp" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="Revamp">
                                        Revamp
                                    </div>
                                </Link>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Link to="#WhyUs" onClick={(e) => onPress(e)} >
                                    <div data-to-scrollspy-id="WhyUs">
                                        Why Us
                                    </div>
                                </Link>
                            </ListItemButton>
                       
                          
                        </List>

                    </div>
                </Box>
            </Drawer>


        </>
    )
}
