import React, { useEffect, useState } from 'react';
import { Container, Grid, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { isMobileOnly, isMobile, isTablet } from 'react-device-detect';

import W1Img from '../../assets/images/home/recent-work/W1.png'
import W2Img from '../../assets/images/home/recent-work/W2.png'
import W3Img from '../../assets/images/home/recent-work/W3.png'
import W4Img from '../../assets/images/home/recent-work/W4.png'
import W5Img from '../../assets/images/home/recent-work/W5.png'
import W6Img from '../../assets/images/home/recent-work/W6.png'


import M1Img from '../../assets/images/home/recent-work/M1.png'
import M2Img from '../../assets/images/home/recent-work/M2.png'
import M3Img from '../../assets/images/home/recent-work/M3.png'
import M4Img from '../../assets/images/home/recent-work/M4.png'
import M5Img from '../../assets/images/home/recent-work/M5.png'

import B1Img from '../../assets/images/home/recent-work/B1.png'
import B2Img from '../../assets/images/home/recent-work/B2.png'
import B3Img from '../../assets/images/home/recent-work/B3.png'
import B4Img from '../../assets/images/home/recent-work/B4.png'
import B5Img from '../../assets/images/home/recent-work/B5.png'
import B6Img from '../../assets/images/home/recent-work/B6.png'

import P1Img from '../../assets/images/home/recent-work/PM1.png'
import P2Img from '../../assets/images/home/recent-work/PM2.png'
import P3Img from '../../assets/images/home/recent-work/PM3.png'
import P4Img from '../../assets/images/home/recent-work/PM4.png'
import P5Img from '../../assets/images/home/recent-work/PM5.png'
import P6Img from '../../assets/images/home/recent-work/PM6.png'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
}));

export default function RecentWork() {
    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
    const [value, setValue] = React.useState('1');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    // $('.grid').isotope({
    //     // options...
    //     itemSelector: '.grid-item',
    //     masonry: {
    //         columnWidth: 200
    //     }
    // });

    useEffect(() => {

        const handleScroll = () => {

            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 150);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])


    return (
        <>
            <section className='recent_work_section'>
                <Container maxWidth="xl" spacing={1} className='custome_container'>
                    <Grid container spacing={4}>
                        <Grid item lg={6} xs={12}>
                            <div className={isScrollValueMoreThanHeaderHeight ? "how_we_work_title work_sticky-top" : "how_we_work_title"}>
                                <h2 className='infi_similar_title'> Our Recent  work<br />
                                    that stand out!</h2>
                                <p className='infi_similar_desc'>
                                    See our latest {isMobileOnly ? "" : <br />}
                                    work here</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item lg={12} xs={12}>
                            <TabContext value={value} sx={{ p: 0 }}>

                                <div className={isScrollValueMoreThanHeaderHeight ? "recent_work_tab_menu recent_work_sticky" : "recent_work_tab_menu"}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" className="tabs_item">
                                        <Tab label="Website" value="1" />
                                        <Tab label="Mobile App" value="2" />
                                        <Tab label="Branding" value="3" />
                                        <Tab label="Print Media" value="4" />
                                    </TabList>
                                </div>

                                <Grid container spacing={2}>
                                    <Grid item lg={12} xs={12} className="filter-container">

                                        <TabPanel value="1" sx={{ p: 0 }} >
                                            <Grid container spacing={2}>
                                                <Grid item lg={6} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={W1Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={6} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={W2Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={7} xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={12} xs={12}>
                                                            <div className='recent_work_img_box'>
                                                                <img src={W3Img} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={6} xs={12}>
                                                            <div className='recent_work_img_box'>
                                                                <img src={W5Img} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={6} xs={12}>
                                                            <div className='recent_work_img_box'>

                                                                <img src={W6Img} />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={5} xs={12}>
                                                    <div className='recent_work_img_box'>

                                                        {/* <img src="https://placehold.co/800x1300" /> */}
                                                        <img src={W4Img} />
                                                    </div>
                                                </Grid>


                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value="2" sx={{ p: 0 }}>
                                            <Grid container spacing={2}>
                                                <Grid item lg={6} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={M1Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={6} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={M2Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={7} xs={12}>
                                                    <Grid container spacing={2}>

                                                        <Grid item lg={12} xs={12}>
                                                            <div className='recent_work_img_box'>
                                                                <img src={M3Img} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <div className='recent_work_img_box'>
                                                                <img src={M5Img} />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={5} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={M4Img} />
                                                    </div>
                                                </Grid>
                                            </Grid> 
                                        </TabPanel>

                                        <TabPanel value="3" sx={{ p: 0 }}>

                                            <Grid container spacing={2}>
                                                <Grid item lg={5} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={B1Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={7} xs={12}>
                                                    <Grid container spacing={2}>

                                                        <Grid item lg={12} xs={12}>
                                                            <div className='recent_work_img_box'>
                                                                <img src={B2Img} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item lg={6} xs={12}>
                                                                    <div className='recent_work_img_box'>
                                                                        <img src={B3Img} />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item lg={6} xs={12}>
                                                                    <div className='recent_work_img_box'>
                                                                        <img src={B4Img} />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={B5Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={6} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={B6Img} />
                                                    </div>
                                                </Grid>

                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value="4" sx={{ p: 0 }}>
                                            <Grid container spacing={2}>

                                                <Grid item lg={7} xs={12}>
                                                    <Grid container spacing={2}>

                                                        <Grid item lg={12} xs={12}>
                                                            <div className='recent_work_img_box'>
                                                                <img src={P1Img} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <div className='recent_work_img_box'>
                                                                <img src={P3Img} />
                                                            </div>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                                <Grid item lg={5} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={P2Img} />
                                                    </div>
                                                </Grid>

                                                <Grid item lg={4} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={P4Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={4} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={P5Img} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={4} xs={12}>
                                                    <div className='recent_work_img_box'>
                                                        <img src={P6Img} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                    </Grid>
                                </Grid>



                            </TabContext>
                        </Grid>
                    </Grid>
                </Container>
            </section>

        </>
    )
}
