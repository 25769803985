import { Container, Grid } from '@mui/material'
import React from 'react'
import '../../assets/css/home.css'

export default function TextAnimate() {
    return (

        <section className='text_animation_section'>
            <Container maxWidth="xl" spacing={2} >
                <Grid container spacing={0}  justifyContent="center">

                    <Grid item lg={8} md={12} sm={12} xs={12}>
                    
                        <div className="slogan__content">
                            <div className="text--l1 slogan__karaoke-content">
                                <span>We thrive on generating ideas that are not just </span>
                                <span>unique but also strategically sound. </span>
                                <span className='text_ani_mt'>We’re here to make your brand stand out in a </span>
                                <span>crowded marketplace. </span>
                            </div>
                            <div className="text--l1 slogan__karaoke-mask">
                                <span
                                    data-aos="true"
                                    data-aos-offset="211.66666666666666"
                                    className="aos-init aos-animate"
                                >
                                   We thrive on generating ideas that are not just 
                                </span>
                                <span
                                    data-aos="true"
                                    data-aos-offset="211.66666666666666"
                                    className="aos-init aos-animate"
                                >
                                  unique but also strategically sound.
                                </span>
                                <span
                                    data-aos="true"
                                    data-aos-offset="211.66666666666666"
                                    className="aos-init text_ani_mt"
                                >
                                   We’re here to make your brand stand out in a
                                </span>
                                <span
                                    data-aos="true"
                                    data-aos-offset="211.66666666666666"
                                    className="aos-init "
                                >
                                   We’re here to make your brand stand out in a
                                </span>
                                <span
                                    data-aos="true"
                                    data-aos-offset="211.66666666666666"
                                    className="aos-init"
                                >
                                  crowded marketplace.
                                </span>

                              
                            </div>
                            {/* <div className="text--l1 test"> </div> */}
                        </div>
                    </Grid>

                </Grid>
            </Container>
        </section >

    )
}
