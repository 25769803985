import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import './assets/css/style.css'
import Contact from "./components/contact/Contact";

function App() {
  return (
    <>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/contact-us" element={<Contact />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
