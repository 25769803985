import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Button, Container, Grid } from '@mui/material'

import Web from '../../assets/images/home/service/web-development.png'
import App from '../../assets/images/home/service/app-development.png'
import UiUx from '../../assets/images/home/service/ux-design.png'
import Digital from '../../assets/images/home/service/digital-marketing.png'
import Graphic from '../../assets/images/home/service/graphic-design.png'
import Print from '../../assets/images/home/service/print-media.png'
import { GoArrowUpRight } from "react-icons/go";


export default function Service() {

    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);


    useEffect(() => {

        const handleScroll = () => {
            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 100);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])



    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section className='service_section'>
                <Container maxWidth="xl" spacing={1} className='custome_container'>
                    <Grid container spacing={4}>
                        <Grid item lg={6} xs={12}>
                            <div className={isScrollValueMoreThanHeaderHeight ? "service_title infi_hm_similar_desc work_sticky-top" : "service_title infi_hm_similar_desc"}>
                                <h2 className='infi_similar_title'> We can Create <br /> for you! </h2>
                                <p className='infi_similar_desc'> Get Unlimited World class creative solutions for your </p>
                            </div>
                        </Grid>
                        
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className='service_box'>
                                <div className='service_hov_content'>
                                    <img src={Web} />
                                </div>
                                <div className='service_hov_h2'>
                                    <h2>Web <br /> Development</h2>
                                </div>
                                <div className='service_hov_content'>
                                    <p> Your website is the face of
                                        your brand. Let us make it
                                        memorable and impactful. </p>

                                </div>

                                <div className='service_overlay'>
                                    <h2>Web <br /> Development</h2>
                                    <ul>
                                        <li>
                                            <p> <GoArrowUpRight /> Custom Web Development </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Frontend & Backend </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> E-Commerce Development </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Web App Development </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> User Testing & QA </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Responsive Web Design </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Web Performance Optimize </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className='service_box'>
                                <div className='service_hov_content'>
                                    <img src={App} />
                                </div>
                                <div className='service_hov_h2'>
                                    <h2>App <br /> Development</h2>
                                </div>
                                <div className='service_hov_content'>
                                    <p> Turning your ideas into
                                        interactive, functional realities
                                        for mobile platforms. </p>
                                </div>
                                <div className='service_overlay'>
                                    <h2>App <br /> Development</h2>
                                    <ul>
                                        <li>
                                            <p> <GoArrowUpRight /> Custom Mobile App </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Cross-Platform App </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Hybrid App Development </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Web App Development </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> API Development </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> User Interface & Experience </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Performance Optimization </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className='service_box'>
                                <div className='service_hov_content'>
                                    <img src={UiUx} />
                                </div>
                                <div className='service_hov_h2'>
                                    <h2>UI/UX <br /> Design </h2>
                                </div>
                                <div className='service_hov_content'>
                                    <p> Crafting intuitive and
                                        captivating UI/UX designs
                                        for your digital success. </p>
                                </div>
                                <div className='service_overlay'>
                                    <h2>UI/UX <br /> Design </h2>
                                    <ul>
                                        <li>
                                            <p> <GoArrowUpRight /> Website & Mobile App </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Landing Pages </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> E-Commerce </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Dashboard & Data Entry </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Wireframing & Prototyping </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Redesign Services </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> User Research </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className='service_box'>
                                <div className='service_hov_content'>
                                    <img src={Digital} />
                                </div>
                                <div className='service_hov_h2'>
                                    <h2>Digital <br /> Marketing </h2>
                                </div>
                                <div className='service_hov_content'>
                                    <p> Don't just be seen,
                                        be remembered. Let us boost
                                        your online visibility. </p>
                                </div>
                                <div className='service_overlay'>
                                    <h2>Digital <br /> Marketing </h2>
                                    <ul>
                                        <li>
                                            <p> <GoArrowUpRight /> Search Engine Optimization </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Social Media Marketing </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Email Marketing </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Content Marketing </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Analytics and Reporting </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Influencer Marketing </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Market Research </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className='service_box'>
                                <div className='service_hov_content'>
                                    <img src={Graphic} />
                                </div>
                                <div className='service_hov_h2'>
                                    <h2> Graphic <br /> Design</h2>
                                </div>

                                <div className='service_hov_content'>
                                    <p> From concept to creation,
                                        we turn your vision into
                                        visual reality. </p>
                                </div>

                                <div className='service_overlay'>
                                    <h2> Graphic <br /> Design</h2>
                                    <ul>
                                        <li>
                                            <p> <GoArrowUpRight /> Logo Design </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Brand Identity Design </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Packaging & Labeling </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Flyer & Brochure Design </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Posters & Banners </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Product Catalog Design </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Social Media Post Design </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className='service_box'>
                                <div className='service_hov_content'>
                                    <img src={Print} />
                                </div>
                                <div className='service_hov_h2'>
                                    <h2>Print <br /> Media</h2>
                                </div>

                                <div className='service_hov_content'>
                                    <p> Bringing your brand to life on
                                        tangible, memorable materials
                                        for a lasting impact.</p>
                                </div>
                                <div className='service_overlay'>
                                    <h2>Print <br /> Media</h2>
                                    <ul>
                                        <li>
                                            <p> <GoArrowUpRight /> Posters & Banners </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> In Shop Branding </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Product Catalog </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Packaging & Labeling </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Exhibition Materials </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Flex & Vinyl Board </p>
                                        </li>
                                        <li>
                                            <p> <GoArrowUpRight /> Acrylic LED Board </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Grid>

                    </Grid>
                </Container>
            </section>
        </>
    )
}
