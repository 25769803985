import { Button, Container, Grid } from '@mui/material'
import React, { useRef } from 'react'
import { HiOutlineArrowDown } from 'react-icons/hi';

import { GrFacebookOption } from "react-icons/gr";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";

import ScrollDown from '../../assets/images/home/scroll-d.svg'
import Down from '../../assets/images/home/down.svg'
import SinceImg from "../../assets/images/home/since.svg"


import "../../assets/css/home.css"

export default function Banner() {
    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

      
    return (
        <>
            <section className='banner_section'>
                <Container maxWidth="xl" spacing={1} >
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12}>
                            <div className='banner_main'>

                                <div className='banner_title_content'>
                                    <div className='title1'>
                                        <h2 data-aos="fade-right" data-aos-delay="500"> Infinite </h2>
                                    </div>
                                    <div className='title2'>
                                        <h2 data-aos="fade-left" data-aos-delay="600"> Creativity </h2>
                                    </div>
                                    <div className='title3'>
                                        <h2 data-aos="fade-right" data-aos-delay="800"> Endless </h2>
                                    </div>
                                    <div className='title4'>
                                        <h2 data-aos="fade-left" data-aos-delay="1000"> Solutions </h2>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <div className='banner_text_description' data-aos="zoom-in"  data-aos-delay="1000"  data-aos-anchor-placement="top-bottom">
                    <h3>
                        "We seamlessly bridge the
                        digital and print worlds”
                    </h3>
                </div>

                <div className='banner_scroll_down' onClick={handleClick}  >
                    <div className='scroll_img' >
                        <img src={ScrollDown} />
                    </div>
                    <img src={Down} className='img_down' />
                </div>

                <div className='banner_since' >
                    {/* <h3 data-aos="zoom-in" data-aos-delay="400"> Since 2010 </h3> */}
                    <img data-aos="zoom-in" data-aos-delay="400" src={SinceImg} />
                </div>

                <div className='social_icn'>
                    <ul>
                        <li data-aos="fade-left" data-aos-delay="500">
                            <div className='banner_social_ic' onClick={() => { window.open('#', '_blank') }}>
                                <GrFacebookOption />
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="700">
                            <div className='banner_social_ic' onClick={() => { window.open('#', '_blank') }}>
                                <FaInstagram />
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="900">
                            <div className='banner_social_ic' onClick={() => { window.open('#', '_blank') }}>
                                <FaXTwitter />
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="1100">
                            <div className='banner_social_ic' onClick={() => { window.open('#', '_blank') }}>
                                <FaWhatsapp />
                            </div>
                        </li>
                    </ul>
                </div>

            </section>

            <div ref={ref}> </div>

        </>
    )
}
