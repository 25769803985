import { Box, Container, Grid, TextField } from '@mui/material'
import React from 'react'
import "../../assets/css/footer.css"

import { BiLogoFacebook } from "react-icons/bi";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import Infi from '../../assets/images/home/infi.svg'
import downArrow from '../../assets/images/home/down-footer.svg'
import LocationImg from '../../assets/images/home/Location.svg'
import CallImg from '../../assets/images/home/Call.svg'
import EmailImg from '../../assets/images/home/Message.svg'
import { PiTelegramLogoThin } from "react-icons/pi";

export default function Footer() {
    return (
        <section className='infi_footer_section'>
            <img src={Infi} className='infi_footer' />
            <img src={downArrow} className='infi_footer_down' />
            <Container maxWidth="xl" spacing={1} className='custome_container'>

                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className='footer_news_title'>
                            <h2>Stay <br /> Connected!  </h2>
                            <p>Subscribe to get Latest Updates and News!</p>
                        </div>
                    </Grid>
                    <Grid item lg={6} sm={12} md={12} xs={12}>
                        <div className='infi_newsletter_inp'>
                            <input type="text" placeholder='Your Email Address' />
                            <div className='ft_tele_circle'>
                                <PiTelegramLogoThin />
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: { lg: 16 } }}>
                        <div className='infi_footer_address'>
                            <ul>
                                <li>

                                    <div className='address_1'>
                                        <span>
                                            <img src={LocationImg} />
                                        </span>
                                        <p>
                                            179, Akbar Ali Complex, M.G. Road, Palasia Square,<br />
                                            Indore, Madhya Pradesh 452001 - INDIA
                                        </p>
                                    </div>

                                </li>

                                <li>
                                    <div className='infi_footer_contact_text'>
                                        <span>
                                            <img src={CallImg} />
                                        </span>
                                        <p>
                                            +91 89623 55707
                                        </p>
                                    </div>
                                    <div className='infi_footer_contact_text'>
                                        <span>
                                            <img src={EmailImg} />
                                        </span>
                                        <p>
                                            info@infisol.com
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12} sx={{ mt: { lg: 16 } }}>
                        <Grid container spacing={{ lg: 4, xs: 1 }}>

                            <Grid item lg={6} md={6} xs={12} >
                                <div className='infi_footer_social_icn' >
                                    <div className='social_text'>
                                        <h2>Facebook</h2>
                                        <p> @infisol_india </p>
                                    </div>
                                    <div className='social_icon'>
                                        <BiLogoFacebook />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                                <div className='infi_footer_social_icn'>
                                    <div className='social_text'>
                                        <h2>Instagram</h2>
                                        <p> @infisol_india </p>
                                    </div>
                                    <div className='social_icon'>
                                        <FaInstagram />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                                <div className='infi_footer_social_icn'>
                                    <div className='social_text'>
                                        <h2>Twitter</h2>
                                        <p> @infisol_india </p>
                                    </div>
                                    <div className='social_icon'>
                                        <FaXTwitter />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                                <div className='infi_footer_social_icn'>
                                    <div className='social_text'>
                                        <h2>Whatsapp</h2>
                                        <p> @infisol_india </p>
                                    </div>
                                    <div className='social_icon'>
                                        <FaWhatsapp />
                                    </div>
                                </div>
                            </Grid>


                        </Grid>


                    </Grid>
                </Grid>

                <Grid Item xs={12}>
                    <div className='infi_footer_copyright'>
                        <p> © 2023 Infinite Creative Solutions. All Rights Reserved. </p>
                    </div>
                </Grid>

            </Container>
        </section>
    )
}
