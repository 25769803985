import React from 'react'

import { Grid } from '@mui/material';
import LogoImg from './LogoImg';
import Marquee from 'react-fast-marquee';


export default function LogoSlide() {

    return (
        <>
            <section className='logo_slide_section'>

                <Grid container spacing={4}>
                    <Grid item lg={2} md={12} xs={12}>
                        <div className='logo_slide_title'>
                            <h2> Brands We’ve <br />
                                Supported </h2>
                        </div>
                    </Grid>
                </Grid>
                <Grid item lg={10} md={12} xs={12}>
                    <div className='logo_slide_main'>
                        <Marquee pauseOnHover={true}>
                            <LogoImg />
                        </Marquee>

                    </div>
                </Grid>

            </section>
        </>
    )
}
