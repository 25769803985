import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material'
import { BsFillStopwatchFill } from "react-icons/bs";
import DownLeft from "../../assets/images/home/down-left.svg"
import Watch from "../../assets/images/home/service/stopwatch.svg"
import Crown from "../../assets/images/home/service/royal-crown.svg"
import Perform from "../../assets/images/home/service/performance.svg"
import WhyBtn from "../../assets/images/home/service/button.svg"
import Money from "../../assets/images/home/service/money.svg"
import Customer from "../../assets/images/home/service/costumer.svg"

export default function WhyWork() {
    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 150);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    return (
        <>
            <section className='why_work_section'>
                <Container maxWidth="xl" spacing={1} className='custome_container'>
                    <Grid container spacing={{lg:4, xs:2}}>
                        <Grid item lg={6}>
                            <div className={isScrollValueMoreThanHeaderHeight ? "how_we_work_title work_sticky-top" : "how_we_work_title"}>
                                <h2 className='infi_similar_title'> Why Work <br /> with <strong> Infisol </strong></h2>
                                <p className='infi_similar_desc'>
                                    We harness the digital realm's
                                    full potential to drive your
                                    success story.
                                </p>
                            </div>
                        </Grid>
                        <Grid item lg={6} sm={12}>
                            <div className='why_work_main'>
                                <ul>
                                    <li>
                                        <div className='why_desc'>
                                            <div className='why_work_text_content'>
                                                <img src={Watch} />
                                                <h3> Super Fast </h3>
                                            </div>
                                            <p> Experience the super-fast difference that fuels your digital success. </p>
                                        </div>

                                        <div className='why_work_icon'>
                                            <img src={DownLeft} />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='why_desc'>
                                            <div className='why_work_text_content'>
                                                <img src={Crown} />
                                                <h3> Premium Quality </h3>
                                            </div>
                                            <p> Because premium quality isn't just a promise, it's our standard. </p>
                                        </div>

                                        <div className='why_work_icon'>
                                            <img src={DownLeft} />
                                        </div>
                                    </li>

                                    <li>
                                        <div className='why_desc'>
                                            <div className='why_work_text_content'>
                                                <img src={Perform} />
                                                <h3> Built to Perform </h3>
                                            </div>
                                            <p> We build to perform, ensuring your IT solutions meet and exceed your expectations. </p>
                                        </div>

                                        <div className='why_work_icon'>
                                            <img src={DownLeft} />
                                        </div>
                                    </li>

                                    <li>
                                        <div className='why_desc'>
                                            <div className='why_work_text_content'>
                                                <img src={WhyBtn} />
                                                <h3> Pause at Any Time </h3>
                                            </div>
                                            <p> Enjoy the freedom to pause at any time, putting you in control of your services. </p>
                                        </div>

                                        <div className='why_work_icon'>
                                            <img src={DownLeft} />
                                        </div>
                                    </li>

                                    <li>
                                        <div className='why_desc'>
                                            <div className='why_work_text_content'>
                                                <img src={Money} />
                                                <h3> Flat Monthly Rate </h3>
                                            </div>
                                            <p>
                                                We offer the simplicity and transparency of a flat monthly rate for your needs. </p>
                                        </div>

                                        <div className='why_work_icon'>
                                            <img src={DownLeft} />
                                        </div>
                                    </li>

                                    <li>
                                        <div className='why_desc'>
                                            <div className='why_work_text_content'>
                                                <img src={Customer} />
                                                <h3> Bring the Squad </h3>
                                            </div>
                                            <p> We're more than a provider; we're your IT squad, dedicated to your success. </p>
                                        </div>

                                        <div className='why_work_icon'>
                                            <img src={DownLeft} />
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </Grid>

                    </Grid>
                </Container>
            </section>



        </>
    )
}
