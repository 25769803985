import React, { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Container, Grid } from '@mui/material'

import TimelineObserver from 'react-timeline-animation';

export default function HowWork() {
    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);


    const [message, setMessage] = useState("");

    const onCallback = () => {
        console.log("awesome");
    };


    function isInViewport(element) {
        console.log(element)
        var rect = element.getBoundingClientRect();
        console.log(rect)
        return (
            rect.top >= 1 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    useEffect(() => {

        const handleScroll = () => {
            var targetDiv = document.getElementById('targetDiv');

            if (isInViewport(targetDiv)) {
                console.log('Target div is currently visible.');
            }
            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 150);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])


    return (
        <>
            <section className='how_work_section'>
                <Container maxWidth="xl" spacing={1} className='custome_container'>
                    <Grid container spacing={4}>
                        <Grid item lg={6}>
                            <div className={isScrollValueMoreThanHeaderHeight ? "how_we_work_title work_sticky-top" : "how_we_work_title"}>
                                <h2 className='infi_similar_title'> How <br />
                                    We Work </h2>
                                <p className='infi_similar_desc'> Here are four key points
                                    to explain how we work
                                    from the first contact to
                                    the final launch </p>
                            </div>
                        </Grid>
                        <Grid item lg={6} id="targetDiv">

                            <div className='timeline_main'>
                                <TimelineObserver
                                    initialColor="#e5e5e5"
                                    fillColor="#151552"
                                    hasReverse={true}
                                    handleObserve={(setObserver) => (

                                        <Timeline
                                            callback={onCallback}
                                            className="timeline"
                                            setObserver={setObserver}

                                        />
                                    )}
                                />

                                <div className='how_work_main'>
                                    <div data-aos="true"
                                        data-aos-offset="211.66666666666666"
                                        className="work_box aos-init aos-animate">
                                        {/* <div className='work_count_circle'>
                                            <h2> 01 </h2>
                                        </div> */}
                                        <div className='work_content'>
                                            <h2>Discover</h2>
                                            <p> Firstly, we explore your project to understand
                                                your unique business requirements and the
                                                objectives you wish to achieve.
                                            </p>
                                        </div>
                                    </div>
                                    <div data-aos="true"
                                        data-aos-offset="211.66666666666666"
                                        className="work_box aos-init">
                                        {/* <div className='work_count_circle'>
                                            <h2> 02 </h2>
                                        </div> */}
                                        <div className='work_content'>
                                            <h2>Planning</h2>
                                            <p> After thoroughly assessing your needs, we draft
                                                a personalized plan designed exclusively for
                                                your brand.
                                            </p>
                                        </div>
                                    </div>
                                    <div data-aos="true"
                                        data-aos-offset="211.66666666666666"
                                        className="work_box aos-init">
                                        {/* <div className='work_count_circle'>
                                            <h2> 03 </h2>
                                        </div> */}
                                        <div className='work_content'>
                                            <h2>Execution</h2>
                                            <p> Next, we transform your creative vision into a
                                                digital or print solution, pushing you closer to
                                                your goals.
                                            </p>
                                        </div>
                                    </div>
                                    <div data-aos="true"
                                        data-aos-offset="211.66666666666666"
                                        className="work_box aos-init">
                                        {/* <div className='work_count_circle'>
                                            <h2> 04 </h2>
                                        </div> */}
                                        <div className='work_content'>
                                            <h2>Launch</h2>
                                            <p> Finally, we introduce your business to the world through
                                                online platforms or print to ensure it reaches its
                                                intended audience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </Grid>

                    </Grid>
                </Container>
            </section>


        </>
    )
}





const Timeline = ({ setObserver, callback }) => {

    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");

    // const timeline1 = useRef(null);
    const timeline2 = useRef(null);
    const timeline3 = useRef(null);
    const timeline4 = useRef(null);

    const circle1 = useRef(null);
    const circle2 = useRef(null);
    const circle3 = useRef(null);
    const circle4 = useRef(null);

    const someCallback = () => {
        setMessage1("Step one");
        callback();
    };

    const someCallback2 = () => {
        setMessage2("Step two");
    };

    const someCallback3 = () => {
        setMessage3("Step three");

    };

    const someCallback4 = () => {
        setMessage4("finish");

    };

    useEffect(() => {
        //   setObserver(timeline1.current);
        setObserver(timeline2.current);
        setObserver(timeline3.current);
        setObserver(timeline4.current);

        setObserver(circle1.current, someCallback);
        setObserver(circle2.current, someCallback2);
        setObserver(circle3.current, someCallback3);
        setObserver(circle4.current, someCallback4);
    }, []);

    return (
        <div className="wrapper">

            <div className="circleWrapper">
                <div id="circle1" ref={circle1} className="circle">
                   <h2>  1 </h2>
                </div>
        
            </div>

            <div id="timeline2" ref={timeline2} className="timeline" />
            <div className="circleWrapper">
                <div id="circle2" ref={circle2} className="circle">
                  <h2>   2 </h2>
                </div>

            </div>

            <div id="timeline3" ref={timeline3} className="timeline" />
            <div className="circleWrapper">
                <div id="circle3" ref={circle3} className="circle">
                    <h2> 3 </h2>
                </div>
             
            </div>

            <div id="timeline4" ref={timeline4} className="timeline" />
            <div className="circleWrapper">
                <div id="circle4" ref={circle4} className="circle">
                   <h2>  4 </h2>
                </div>
             
            </div>

        </div>
    );
};
