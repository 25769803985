import React, { useEffect, useState } from 'react';
import { Button, Container, Grid } from '@mui/material'
import { TfiArrowTopRight } from "react-icons/tfi";
import { Link } from 'react-router-dom';


export default function StartProject() {


    return (
        <>
            <section className='start_project_section'>
                <Container maxWidth="xl" spacing={1} className='custome_container'>
                    <Grid container spacing={2}>
                        <Grid item lg={12} xs={12}>
                            <div>
                                <p className='start_proj_p'>Feel free to Talk with us :) </p>
                            </div>
                            <div className='start_proj_main'>
                                <Link to="/contact-us">
                                    <div className='st_over_hid'>
                                        <div className='proj_start_title'>
                                            <h2 className='static_title'> Let’s start a project </h2>
                                            <h2 className='hover_title'> Let’s start a project </h2>
                                        </div>
                                        <div className='arrow_up'>
                                            <TfiArrowTopRight className='arrow_static' />
                                            <TfiArrowTopRight className='arrow_hover' />
                                        </div>
                                    </div>
                                </Link>

                            </div>



                        </Grid>

                    </Grid>
                </Container>
            </section>
        </>
    )
}
