import React from 'react'
import Logo1 from '../../assets/images/home/logo-slide/1.png'
import Logo2 from '../../assets/images/home/logo-slide/2.png'
import Logo3 from '../../assets/images/home/logo-slide/3.png'
import Logo4 from '../../assets/images/home/logo-slide/4.png'
import Logo5 from '../../assets/images/home/logo-slide/5.png'
import Logo6 from '../../assets/images/home/logo-slide/6.png'
import Logo7 from '../../assets/images/home/logo-slide/7.png'
import Logo8 from '../../assets/images/home/logo-slide/8.png'
import Logo9 from '../../assets/images/home/logo-slide/9.png'
import Logo10 from '../../assets/images/home/logo-slide/10.png'
import Logo11 from '../../assets/images/home/logo-slide/11.png'
import Logo12 from '../../assets/images/home/logo-slide/12.png'
import Logo13 from '../../assets/images/home/logo-slide/13.png'
import Logo14 from '../../assets/images/home/logo-slide/14.png'
import Logo15 from '../../assets/images/home/logo-slide/15.png'
import Logo16 from '../../assets/images/home/logo-slide/16.png'
import Logo17 from '../../assets/images/home/logo-slide/17.png'
import Logo18 from '../../assets/images/home/logo-slide/18.png'
import Logo19 from '../../assets/images/home/logo-slide/19.png'
import Logo20 from '../../assets/images/home/logo-slide/20.png'
import Logo21 from '../../assets/images/home/logo-slide/21.png'
import Logo22 from '../../assets/images/home/logo-slide/22.png'


const logos = [
    { src: Logo1, alt: 'img1' },
    { src: Logo2, alt: 'img2' },
    { src: Logo3, alt: 'img3' },
    { src: Logo4, alt: 'img4' },
    { src: Logo5, alt: 'img5' },
    { src: Logo6, alt: 'img6' },
    { src: Logo7, alt: 'img7' },
    { src: Logo8, alt: 'img8' },
    { src: Logo9, alt: 'img9' },
    { src: Logo10, alt: 'img10' },
    { src: Logo11, alt: 'img11' },
    { src: Logo12, alt: 'img12' },
    { src: Logo13, alt: 'img13' },
    { src: Logo14, alt: 'img14' },
    { src: Logo15, alt: 'img15' },
    { src: Logo16, alt: 'img16' },
    { src: Logo17, alt: 'img17' },
    { src: Logo18, alt: 'img18' },
    { src: Logo19, alt: 'img19' },
    { src: Logo20, alt: 'img20' },
    { src: Logo21, alt: 'img21' },
    { src: Logo22, alt: 'img22' },

];
export default function LogoImg() {
    return (
        <>
            <div className='logo-slider'>
                {logos.map((logo, index) => (
                    <div className="logo-item" key={index}>
                        <img src={logo.src} alt={logo.alt} />
                    </div>
                ))}
            </div>
        </>
    )
}
