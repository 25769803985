import React, { useEffect, useRef, useState } from "react";

import TimelineObserver from "react-timeline-animation";
import TextAnimate from './TextAnimate';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Banner from './Banner';
import LogoSlide from './LogoSlide';
import Project from './Project';
import '../../assets/css/style.css'
import Service from './Service';
import HowWork from './HowWork';
import RecentWork from './RecentWork';
import Revemp from './Revemp';
import WhyWork from './WhyWork';
import Technologies from './Technologies';
import { ParallaxProvider } from 'react-scroll-parallax';
import StartProject from "./StartProject";
import Footer from "../../container/footer/Footer";
import Header from "../../container/header/Header";
import ScrollSpy from "react-ui-scrollspy";


export default function Home() {


    useEffect(() => {
        AOS.init({
            duration: 1000,
            // disable: 'mobile'
        });
    }, []);
    return (
        <>

            <Header />
            <ScrollSpy
                scrollThrottle={100}
                onUpdateCallback={(id) => console.log(id)}
                useBoxMethod={false}
            >
                <Banner />

                <LogoSlide />

                <TextAnimate data-aos="fade-up-left" />

                <Project />
                
                <div id="service">
                    <Service />
                </div>

                <div id="RecentWork">
                    <RecentWork />
                </div>

                <div id="HowWeWork">
                    <HowWork data-aos="fade-up-left" />
                </div>

                <div id="Revamp">
                    <Revemp />
                </div>

                <div id="WhyUs">
                    <WhyWork />
                </div>

                <ParallaxProvider>
                    <Technologies />
                </ParallaxProvider>

                <StartProject />

                <Footer />
            </ScrollSpy>
        </>
    )
}

